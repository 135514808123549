var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

if (window.MutationObserver) {
  var element = document.createElement("div");
  element.innerHTML = "<div><div></div></div>";
  new MutationObserver(function (mutations, observer) {
    observer.disconnect();

    if (mutations[0] && mutations[0].type == "childList" && mutations[0].removedNodes[0].childNodes.length == 0) {
      var prototype = HTMLElement.prototype;
      var descriptor = Object.getOwnPropertyDescriptor(prototype, "innerHTML");

      if (descriptor && descriptor.set) {
        Object.defineProperty(prototype, "innerHTML", {
          set: function (value) {
            while ((this || _global).lastChild) this.removeChild((this || _global).lastChild);

            descriptor.set.call(this || _global, value);
          }
        });
      }
    }
  }).observe(element, {
    childList: true,
    subtree: true
  });
  element.innerHTML = "";
}

export default {};